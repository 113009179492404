<template>
  <ValidationProvider
    :rules="validationRules"
    class="question supported-keyboard column is-half"
    tag="div"
    v-slot="{ errors }"
  >
    <div class="question-text">
      <p class="help is-danger" v-if="errors[0]">{{ errors[0] }}</p>
    </div>
    <div class="question-content card">
      <div class="card-content">
        <template v-if="categories">
          <div
            :key="index"
            v-for="(category, index) in answers"
            class="category-content"
          >
            <p
              v-if="category.display_title && category.answers.length > 0"
              class="category-title"
            >
              {{ category.title[lang] || Object.values(category.title)[0] }}
            </p>
            <b-field
              :key="answer.id"
              v-for="answer in category.answers"
              class="mb-4"
            >
              <b-radio-button
                @keydown.native="keyHandler"
                class="answer"
                type="is-primary"
                :disabled="loading"
                :native-value="answer.code"
                :value="value[`${question.qid}`]"
                @input="onChange"
              >
                <span class="option" v-html="answer.answer[lang]"></span>
              </b-radio-button>
            </b-field>
          </div>
        </template>
        <template v-else>
          <b-field :key="answer.id" v-for="answer in answers" class="mb-4">
            <b-radio-button
              @keydown.native="keyHandler"
              class="answer"
              type="is-primary"
              :disabled="loading"
              :native-value="answer.code"
              :value="value[`${question.qid}`]"
              @input="onChange"
            >
              <span class="option" v-html="answer.answer[lang]"></span>
            </b-radio-button>
          </b-field>
        </template>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
import { QuestionMixin } from "@/mixins/question";
import { KeyboardBindingMixin } from "@/mixins/keyboard-binding";
import { UnFocusMixin } from "@/mixins/unfocus";

export default {
  name: "SingleAnswer",
  mixins: [QuestionMixin, KeyboardBindingMixin, UnFocusMixin],
  methods: {
    async keyHandler(e) {
      if (e.key === "Enter") {
        await this.$emit("is-enter");
      }
    },
    async onChange(value) {
      await this.$emit("input", { [this.question.qid]: `${value}` });
      await this.$emit("move-next");
    },
  },
};
</script>
